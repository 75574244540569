import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { CrossIcon } from '../../assets/icons';
import { TermsTab } from '../../pages/Terms';
import { cookieSettingsStore } from '../../storage/cookie-settings';
import Button from '../Button';
import { Sheet, SheetContent } from '../Sheet';
import { Switch } from '../Switch';

export type CookieSettingsModalProps = {
  handleCloseModal: () => void;
  handleOpenCookieModal?: () => void;
  isOpenModal: boolean;
  initialValues?: {
    essential: boolean;
    functional: boolean;
    analytics: boolean;
    marketing: boolean;
  } | null;
};

export const CookieSettingsModal: FC<CookieSettingsModalProps> = ({
  isOpenModal,
  handleCloseModal,
  handleOpenCookieModal,
  initialValues,
}) => {
  const [isFunctionalChecked, setIsFunctionalChecked] = useState<boolean>(
    initialValues?.functional || false,
  );
  const [isAnalyticsChecked, setIsAnalyticsChecked] = useState<boolean>(
    initialValues?.analytics || false,
  );
  const [isMarketingChecked, setIsMarketingChecked] = useState<boolean>(
    initialValues?.marketing || false,
  );

  const handleAcceptAll = () => {
    setIsAnalyticsChecked(true);
    setIsFunctionalChecked(true);
    setIsMarketingChecked(true);

    cookieSettingsStore.set({
      essential: true,
      functional: true,
      analytics: true,
      marketing: true,
    });
    handleCloseModal();
  };

  const handleSaveSettings = () => {
    cookieSettingsStore.set({
      essential: true,
      functional: isFunctionalChecked,
      analytics: isAnalyticsChecked,
      marketing: isMarketingChecked,
    });
    handleCloseModal();
  };

  const handleCloseWithoutChoice = () => {
    handleCloseModal();
    if (handleOpenCookieModal) {
      handleOpenCookieModal();
    }
  };

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[557px] border-transparent bg-transparent p-2 shadow-3xl"
        onInteractOutside={handleCloseWithoutChoice}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-6 overflow-hidden rounded-[4px] bg-white p-6">
          <div className="flex flex-col gap-4">
            <div className="flex w-full items-center justify-between">
              <span className="text-xl font-semibold text-gray-700">Cookie Settings</span>
              <Button
                className="h-fit w-fit rounded p-2 transition-colors hover:bg-gray-200"
                onClick={handleCloseWithoutChoice}
                styleType="NONE"
              >
                <CrossIcon className="h-3 w-3" iconColor="#344054" />
              </Button>
            </div>
            <span className="text-xs font-[450] text-gray-500">
              By clicking "Accept All Cookies," you consent to the storage of cookies on your device
              to enhance your browsing experience, analyze site usage, and tailor our marketing
              efforts. For more details, please visit our{' '}
              <Link
                className="inline h-fit w-fit text-brand-700 underline"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.POLICY }}
                to={'/terms-of-service'}
              >
                Privacy Policy
              </Link>
            </span>
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">Essential</span>
                  <Switch checked={true} disabled />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  Your current Admin privileges will be revoked once the new Admin is granted
                  access.
                </span>
              </div>
            </div>

            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">Personalised</span>
                  <Switch
                    checked={isFunctionalChecked}
                    onChange={(state) => {
                      setIsFunctionalChecked(state);
                    }}
                  />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  Cookies allow us to store your preferences, such as your username, language, and
                  region.
                </span>
              </div>
            </div>

            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">Analytics</span>
                  <Switch
                    checked={isAnalyticsChecked}
                    onChange={(state) => {
                      setIsAnalyticsChecked(state);
                    }}
                  />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  These Cookies help us analyze how our website performs, track user interactions,
                  and identify potential technical issues.
                </span>
              </div>
            </div>

            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">Marketing</span>
                  <Switch
                    checked={isMarketingChecked}
                    onChange={(state) => {
                      setIsMarketingChecked(state);
                    }}
                  />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  These Cookies allow us to show you ads that are more relevant to your interests.
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between gap-3">
            <Button
              className="w-fit text-nowrap border-[1px] border-gray-300 bg-transparent px-6 py-[10px] text-sm font-[550] text-gray-700"
              onClick={handleSaveSettings}
              styleType="NONE"
              type="button"
            >
              Save Settings
            </Button>
            <Button
              className="h-full w-full text-nowrap py-[10px] text-sm font-[550] text-white"
              onClick={handleAcceptAll}
              styleType="BLUE_ROUNDED"
              type="button"
            >
              Accept all
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
