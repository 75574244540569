import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SolutionsConstructor } from './SolutionsConstructor';
import { solutions, SolutionVariant, solutionVariantMap } from './types';

export const Solutions: FC = () => {
  const { tab } = useParams();
  const location = useLocation();

  const initialTab: SolutionVariant = solutionVariantMap.includes(tab as SolutionVariant)
    ? tab
    : location.state || SolutionVariant.ENHANCED_INVESTOR_RELATIONS;

  return <SolutionsConstructor {...solutions[initialTab]} />;
};
