import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CapQuestLogo, FacebookIcon, LinkedInIcon, TwitterIcon } from '../../assets/icons';
import { useModalState } from '../../hooks/useModalState';
import { SolutionVariant } from '../../pages/Solutions/types';
import { TermsTab } from '../../pages/Terms';
import { cookieSettingsStore } from '../../storage/cookie-settings';
import Button from '../Button';
import { CookieSettingsModal } from '../CookiePolicy';

export const Footer: FC = () => {
  // const [language, setLanguage] = useState<'English' | 'French'>('English');
  // const [showLanguage, setShowLanguage] = useState<boolean>(false);
  const navigate = useNavigate();
  const cookieSettings = cookieSettingsStore.get();

  const {
    isOpen: isOpenCookieSettingsModal,
    handleOpenModal: handleOpenCookieSettingsModal,
    handleCloseModal: handleCloseCookieSettingsModal,
  } = useModalState();

  return (
    <>
      <CookieSettingsModal
        handleCloseModal={handleCloseCookieSettingsModal}
        initialValues={cookieSettings}
        isOpenModal={isOpenCookieSettingsModal}
      />
      <footer
        className="flex flex-col items-center justify-center gap-4 px-6 pb-4 pt-[84px]"
        style={{ background: 'linear-gradient(180deg, #020811 0%, #0A1D3A 100%)' }}
      >
        <div className="flex w-full max-w-[1280px] flex-col items-start justify-between gap-10 border-b-[1px] border-[#E6F0FF1A] pb-10 lg:flex-row">
          <div
            className="flex cursor-pointer items-center"
            onClick={() => {
              navigate('/');
              window.scrollTo(0, 0);
            }}
          >
            <CapQuestLogo variant="LIGHT" />
            <span className="text-[16px] font-semibold leading-[2] text-white">CapQuest</span>
          </div>

          <div className="flex w-full flex-col items-start justify-end gap-6 text-start xlg:flex-row xlg:gap-4 lg:gap-16">
            <div>
              <span className="px-2 py-[10px] text-[16px] font-[550] leading-[1.25] text-white">
                Solutions
              </span>
              <div
                className="flex cursor-pointer flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/solutions', { state: SolutionVariant.EQUITY_PLAN_AUTOMATION });
                }}
              >
                ESOP Management
              </div>
              <div
                className="flex cursor-pointer flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/solutions', {
                    state: SolutionVariant.OPTIMIZED_FUNDRAISING_INSTRUMENTS,
                  });
                }}
              >
                Financial Tools
              </div>
              <div
                className="flex cursor-pointer flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/solutions', { state: SolutionVariant.SIMPLIFIED_CAP_TABLE });
                }}
              >
                Cap Table Management
              </div>
              <div
                className="flex cursor-pointer flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/solutions', { state: SolutionVariant.ENHANCED_INVESTOR_RELATIONS });
                }}
              >
                Investor Relations
              </div>
              <div
                className="flex cursor-pointer flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/solutions', { state: SolutionVariant.EFFORTLESS_SCENARIO_MODELING });
                }}
              >
                Scenario Modeling
              </div>
            </div>

            <div>
              <span className="px-2 py-[10px] text-[16px] font-[550] leading-[1.25] text-white">
                Company
              </span>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                to={'/about'}
              >
                About us
              </Link>
              {/* <Link
              className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
              onClick={() => window.scrollTo(0, 0)}
              to={'/pricing'}
            >
              Pricing
            </Link> */}
              {/* <Link
              className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
              onClick={() => window.scrollTo(0, 0)}
              to={'/contact-sales'}
            >
              Perks & Partnerships
            </Link> */}
            </div>

            {/* <div>
            <span className="px-2 py-[10px] text-[16px] font-[550] leading-[1.25] text-white">
              Resources
            </span>
            <Link
              className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
              onClick={() => window.scrollTo(0, 0)}
              to={'/guides'}
            >
              Guides
            </Link>
            <Link
              className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
              onClick={() => window.scrollTo(0, 0)}
              to={'/templates'}
            >
              Templates
            </Link>
            <Link
              className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
              onClick={() => window.scrollTo(0, 0)}
              to={'/faq'}
            >
              FAQs
            </Link>
          </div> */}

            <div>
              <span className="px-2 py-[10px] text-[16px] font-[550] leading-[1.25] text-white">
                Legal
              </span>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.TERMS }}
                to={'/terms-of-service'}
              >
                Terms & Conditions
              </Link>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.POLICY }}
                to={'/terms-of-service'}
              >
                Privacy Policy
              </Link>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.COOKIES }}
                to={'/terms-of-service'}
              >
                Cookie Policy
              </Link>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.DPA }}
                to={'/terms-of-service'}
              >
                DPA
              </Link>
              <Button
                className="items-start justify-start gap-2 px-2 pt-2 text-start text-sm text-[#F2F4F7]"
                onClick={handleOpenCookieSettingsModal}
              >
                Cookie Settings
              </Button>
            </div>

            <div>
              <span className="px-2 py-[10px] text-[16px] font-[550] leading-[1.25] text-white">
                Contact
              </span>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                to={'/contact-sales'}
              >
                Contact us
              </Link>
              <Link
                className="flex flex-col gap-2 px-2 pt-2 text-sm text-[#F2F4F7]"
                onClick={() => window.scrollTo(0, 0)}
                to={'/contact-sales'}
              >
                Talk to sales
              </Link>
            </div>
          </div>
        </div>
        <div className="flex w-full max-w-[1280px] items-center justify-between gap-4">
          <div className="flex items-center gap-6">
            <a
              className="cursor-pointer"
              href="https://x.com/CapQuest_io"
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              <TwitterIcon />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.linkedin.com/company/104307332"
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              <LinkedInIcon />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/capquest.io/"
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              <FacebookIcon />
            </a>
          </div>
          <span className="hidden text-label-sm text-[#CCCCCC] xlg:block">
            © Copyright {new Date().getFullYear()} CapQuest. All rights reserved
          </span>
          {/* <Button
          className="relative w-auto gap-2 bg-white px-3 py-[6px]"
          onClick={() => setShowLanguage(!showLanguage)}
          styleType="NONE"
        >
          {language}
          <ChevronDownIcon />
          {showLanguage && (
            <div className="absolute -top-[90px] flex h-fit w-full flex-col gap-2 rounded border bg-white p-2 shadow-xl">
              <span
                className="w-full rounded px-3 py-1.5 text-gray-700 hover:cursor-pointer hover:bg-brand-25"
                onClick={() => setLanguage('English')}
              >
                English
              </span>
              <span
                className="w-full rounded px-3 py-1.5 text-gray-700 hover:cursor-pointer hover:bg-brand-25"
                onClick={() => setLanguage('French')}
              >
                French
              </span>
            </div>
          )}
        </Button> */}
        </div>
      </footer>
    </>
  );
};
